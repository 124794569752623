<template>
    <b-card>
        <b-row>
            <b-col cols="4">
                <b-form-group label="Telefone" label-for="immobile">
                    <b-form-input v-model="talk.phone"
                    v-mask="['(##) #####-####']" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4">
                <b-form-group label="Mensagem" label-for="immobile">
                    <b-form-textarea v-model="talk.msg" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="4">
                <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="talk.isAddUrl"
                    name="checkbox-1"
                    @change="check">
                    Adicionar URL do anúncio?
                </b-form-checkbox>
            </b-col>
        </b-row>

        <b-row class="pt-1 justify-content-end">
            <b-col sm="2" >
                <b-button  class="btn-block" variant="primary" @click="$router.go(-1)">Voltar</b-button>
            </b-col>
            <b-col sm="2" >
                <b-button  class="btn-block" variant="primary" @click="save()">Salvar</b-button>
            </b-col>

        </b-row>
    </b-card>
</template>

<script>
import { BFormGroup, BRow, BCard, BCol, BButton, BFormCheckbox, BFormTextarea, BFormInput  } from 'bootstrap-vue'
import  BrokerAssociated  from '../../checkout-oppotunity/BrokerAssociated.vue'
import MoneyInput from '@/components/MoneyInput.vue'
import formatter from '@/mixins/formatter'
import toast from '@/mixins/toast'
import Ripple from 'vue-ripple-directive'
import TalkSpecialistConfigService from '@/services/Investment/TalkSpecialistConfigService'

export default {
    components: {
        BFormGroup,
        BButton,
        BRow, 
        MoneyInput,
        BCol, 
        BrokerAssociated,
        BFormCheckbox,
        BFormTextarea,
        BFormInput,
        BCard
    },
    created() {
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    },
    destroyed() {
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
    },
    mixins:[formatter, toast],
    directives: {
        Ripple,
    },

    data(){
        return {
            talk: { phone: null, msg: null, isAddUrl: true },            
        }
    },

    methods: {

        async getConfigTalk() {
            this.talk = (await TalkSpecialistConfigService.GetTaskSpecialistConfigForEdit()).data.result.taskSpecialistConfig
        },

        async save(){
            try {
                await TalkSpecialistConfigService.createOrEdit(this.talk)
                this.msgSuccess('Salvo com sucesso')
            } catch(error){
                this.msgError(error.response.data.error.message)
            }
        }
    },

    mounted() {        
        this.getConfigTalk()
    }
}
</script>
<style>
</style>
